.App {
  margin-left:5pt;
  display:flex;
}

/* Sidebar */
.sidebar{
  Height:100vh;
}

/* Tip Timeline */
.tipsTimeline{
  margin-top:2pt;
  overflow: scroll;
  height: 90vh;
}
.tipTimelineHeader{
  padding-left:10pt;
}
.tipHeaders {
  margin-top:5pt;
}
.tipTitle{
  font-size:.9rem;
  color:rgb(92, 92, 92);
}
.tipDiv{
  margin-top:5pt;
  margin-bottom: 10pt;
  margin-right:5pt;
}
.tipHeaderGeneratedAt{
  float:right;
  margin-top:4pt;
  font-size:.85rem;
  color:rgb(92, 92, 92);
}
.tipHeaderMessage{
  margin-top:5pt;
  margin-bottom:5pt;
}
.tipFooterItem{
  margin-right:10pt;
  color:dimgrey;
}

/* Tip Details Timeline*/
.tipDetails {
  margin:5pt;
}
.tipDetailsTimeline{
  overflow: scroll;
  height: 89vh;
}
.tipDetailDiv{
  margin-bottom:10pt;
}
.tipDetailsDropdown{
    display:inline-block;
}
.tipDetailFooterMessage{
  margin-top: 2pt;
}
.tipDetailTableHeader{
  font-size: 12pt;
}
.tipDetailTable{
  font-size:10pt;
}
.tipDetailHeaderGeneratedAt{
  float:right;
  margin-top:0pt;
}
.tipDetailFooterGeneratedAt{
  margin-top:0pt;
  padding-top:0pt;
  font-size:.9rem;
}

/* Tip Grid View */
.tipsGridView {
  margin-left:10pt;
  margin-top:5pt;
  width:80vw
}
.rdt_TableCell{
  font-size:11pt;
}
.rdt_TableHeadRow{
  font-size:11pt;
}
/* Tip Timeline Details */

/* Activities */
.tipActivities {
  margin-left:10pt;
  margin-top:5pt;
}

/* Uploader */
.uploaderDiv{
  margin-left:10pt;
  margin-right:10pt;
  margin-top:5pt;
}

/* Charts */
.tipDetailChartDivSmall{
  border:1pt solid rgb(182, 182, 182);
  border-radius: 2pt;
  background-color: rgb(245, 245, 245);
}